<template>
    <login-form></login-form>
</template>
<script>

import LoginForm from "./LoginForm";
export default {
  components:{
    LoginForm
  }
}
</script>
